<template>
    <div class="container">
        <h2>CA Editor</h2>
    </div>
</template>

<script>
export default {
    name: 'IndexPage',
};
</script>
